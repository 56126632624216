@import "../../styles/styles.scss";

.checkout-confirmed {
  text-align: center;
  width: 35rem;
  margin: auto;

  .checkout-confirmed-card {
    background-color: lighten($color: $green, $amount: 68);
    border-radius: 10px;
    border: 1px solid $green;
    padding: 2rem;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin: 3rem 0 1.5rem;

    h4 {
      margin: 2rem 0 0;
    }
  }
  .checkout-confirmed-details {
    a {
      color: $primary-color;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
        color: darken($color: $primary-color, $amount: 5);
      }
    }
  }
}
