@import "../../styles/styles.scss";

.homeCompetitions {
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;

  .not-subscribed {
    filter: grayscale(100%);
  }

  .domestic-leagues-title {
    margin: 3rem 0 1rem;
  }

  .domestic-leagues {
    margin-top: 2rem;

    .m-1 {
      width: 20rem;
      height: auto;
    }
  }

  h2 {
    color: $secondary-color;
    margin: 1rem auto;
    @include transition-ease;
  }

  .section-divider {
    margin: 0 auto;
  }

  .logos {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    .logo {
      margin: 2rem;

      a {
        text-decoration: none;
      }

      h2 {
        color: $secondary-color;
        visibility: hidden;
        opacity: 0;
      }

      img {
        width: 220px;
      }

      .champions-img {
        width: 240px;
      }

      &:hover {
        h2 {
          visibility: visible;
          opacity: 1;
          animation: slide-up 500ms cubic-bezier(0.42, 0, 0.59, 1.75);
        }
        img {
          opacity: 0.8;
          border: solid 2px transparent;
        }
        cursor: pointer;
      }
    }
  }
}

@include media-s {
  .homeCompetitions {
    padding: 3rem 0 0;

    .domestic-leagues {
      .m-1 {
        width: 80%;
      }
    }

    .logos {
      flex-flow: column wrap;

      .logo {
        margin: 1rem 0;

        h2 {
          margin: 0.5rem auto;
        }
        img {
          width: 200px;
        }
        .champions-img {
          width: 220px;
        }
      }
    }
  }
}
