@import "../../styles/styles.scss";

.confirm-email {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 8% auto;
  text-align: center;

  .login-link {
    @include card;
    margin-top: 1rem;
    padding: 1rem;
    text-align: center;
    color: $secondary-color;
    font-size: $small;
    font-family: $body-font;

    a {
      border: none;
      background-color: transparent;
      font-size: $regular;
      font-weight: bold;
      font-family: $body-font;
      margin-left: 0.5rem;
      color: $primary-color;
      cursor: pointer;
      text-decoration: none;
      @include transition-ease;

      &:hover {
        color: $accent-color;
      }
    }
  }
}

@include media-s {
  .confirm-email {
    min-width: 300px;
    margin-left: 1px;
    margin-right: 1px;

    .card {
      width: 80%;
      min-width: 300px;
    }

    .login-link {
      width: 80%;
      min-width: 300px;
    }
  }
}
