@import "./variables";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: $body-font;
  background-color: $background-color;
}

.centered {
  text-align: center;
}

.section-divider {
  height: 0.25rem;
  width: 80%;
  margin: 2rem auto;
  background-image: linear-gradient(
    to right,
    #e3710f,
    #df6a0b,
    #db6208,
    #d65b05,
    #d25303,
    #d25303,
    #d25303,
    #d25303,
    #d65b05,
    #db6208,
    #df6a0b,
    #e3710f
  );
}

.link {
  color: $primary-color;
  @include transition-ease;

  &:hover {
    color: $accent-color;
  }
}

.m-1 {
  margin: 1rem;
}

.m-2 {
  margin: 2rem;
}

.my-1 {
  margin: 1rem 0;
}

.other-information {
  font-weight: normal;
}

.cursor-pointer {
  cursor: pointer;
}

.user-select-none {
  user-select: none;
}
