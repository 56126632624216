@import "../../styles/styles.scss";

.vision {
  text-align: center;

  .information {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-start;

    div {
      width: 26rem;
      padding: 3rem;
      margin: 0.5rem;

      h2 {
        margin-bottom: 1rem;
      }
    }
  }
}

@include media-m {
  .vision {
    margin: 0 auto;

    .information {
      div {
        width: 20rem;
        padding: 3rem 0;
      }
    }
  }
}

@include media-s {
  .vision {
    margin: 0 auto;
    width: 100%;

    .information {
      div {
        width: 10rem;
        padding: 2rem 0;

        img {
          width: 4rem;
        }

        h2 {
          font-size: 1.4rem;
        }

        h3 {
          font-size: $small;
        }
      }
    }
  }
}
