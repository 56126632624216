@import "../../styles/styles.scss";

.home {
  .subscribe-div {
    margin: 1rem auto 5rem;

    a {
      color: $primary-color;
    }
    .subscribe-link {
      color: $primary-color;
    }
  }

  .contact-div {
    margin-top: 2rem;
    margin-bottom: 1rem;
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      margin-bottom: 0.5rem;
    }

    img {
      @include transition-ease;
      height: 3rem;
      margin-right: 0.5rem;
    }
  }
}
