@import "../../../styles/styles.scss";

.custom-filter-wrapper {
  position: relative;
  margin: 0 1rem 1rem;

  .filter {
    position: relative;
    display: flex;
    flex-flow: row wrap;
  }

  .filter-header {
    display: flex;
    align-items: center;
    padding: 1rem;
    cursor: pointer;
    width: 20rem;

    &.border-bottom {
      border-bottom: solid 3px $primary-color;
    }
  }

  .filter-title {
    position: relative;
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    width: 100%;
    background: transparent;
    border: none;
    color: $secondary-color;
    font-family: $heading-font;
    font-size: $regular;
    font-weight: bold;
    user-select: none;
  }

  .filter-options {
    max-height: 30rem;
    overflow: auto;
    position: absolute;
    display: block;
    opacity: 0;
    visibility: hidden;
    z-index: 2;
    top: 100%;
    left: 0;
    right: 0;
  }

  .filter.open .filter-options {
    opacity: 1;
    visibility: visible;
    box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.2);
    background-color: $background-color;
  }

  .filter-option {
    position: relative;
    width: 100%;

    &.selected {
      display: flex;
      flex-flow: row wrap;
      cursor: pointer;
      padding: 1rem;
      font-family: $heading-font;
      font-size: $regular;
      font-weight: bold;
      color: $secondary-color;

      &:hover {
        background-color: lighten($color: $primary-color, $amount: 52%);
      }
    }

    &.not-allowed {
      background-color: lighten($color: $secondary-color, $amount: 77%);

      &:hover {
        background-color: lighten($color: $secondary-color, $amount: 77%);
      }
    }

    span {
      margin-left: 0.5rem;
    }
  }

  .filter-option-icon {
    margin-right: 0.5rem;

    svg {
      margin-top: 0.1rem;
      height: 20px;
      width: 20px;
    }
  }
}

.media-filter-select {
  appearance: none;
  background: transparent;
  border: none;
  border-bottom: solid 3px $primary-color;
  color: $secondary-color;
  cursor: pointer;
  font-family: $heading-font;
  font-size: $small;
  font-weight: bold;
  height: 3rem;
  margin-bottom: 0.5rem;
  outline: none;
  text-align: center;
}
