@import "../../styles/styles.scss";

.competitions {
  margin: 0 auto;
  padding: 0 6rem 10rem;
  text-align: center;

  h2 {
    color: $secondary-color;
    margin: 1rem auto;
    @include transition-ease;
  }

  .section-divider {
    margin: 0 auto;
  }

  .logos {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    .logo {
      width: 20%;

      a {
        text-decoration: none;
      }

      h2 {
        color: $secondary-color;
        visibility: hidden;
        opacity: 0;
      }

      img {
        width: 200px;
      }

      .champions-img {
        width: 220px;
      }

      .lkl-img {
        padding-top: 3.3rem;
      }

      &:hover {
        h2 {
          visibility: visible;
          opacity: 1;
          animation: slide-up 500ms cubic-bezier(0.42, 0, 0.59, 1.75);
        }
        img {
          opacity: 0.8;
          border: solid 2px transparent;
        }
        cursor: pointer;
      }
    }
  }
}

.competitions-domestic-leagues {
  .custom-filter-wrapper .filter .filter-header { 
    border-bottom: solid 3px #8c8c8c;
  }
  
  .media-filter-select {
    border-bottom: solid 3px #8c8c8c;
  }
}

@include media-l {
  .competitions {
    .logos {
      .logo {
        width: 30%;
      }
    }
  }
}

@include media-m {
  .competitions {
    .logos {
      .logo {
        width: 45%;
      }
    }
  }

  .competitions-domestic-leagues {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}

@include media-s {
  .competitions {
    padding: 3rem 0 0;
    .logos {
      flex-flow: column wrap;

      .logo {
        margin: 1rem 0;
        width: 100%;

        h2 {
          margin: 0.5rem auto;
        }
        img {
          width: 200px;
        }
        .champions-img {
          width: 220px;
        }
      }
    }
  }
}
