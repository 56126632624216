@import "../../styles/styles.scss";

.account-subscription {
  margin-top: 1.6rem;
  margin-right: 1rem;
  margin-left: 1rem;

  border-radius: 2px;

  font-family: $heading-font;
  font-size: $small;
  font-weight: bold;
  text-decoration: none;

  a {
    text-decoration: none;
    color: $blue;

    display: flex;
    align-items: center;

    span {
      margin-left: 0.4rem;
      font-weight: normal;
    }
  }
}

.account-subscription-valid {
  @extend .account-subscription;
  a {
    color: $primary-color;
  }
}
