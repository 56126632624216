@import "../../styles/styles.scss";

.statistics-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.statistics {
  width: 100%;
  flex: 1;
  min-width: 320px;
  display: flex;
  flex-direction: column;

  .info-div {
    text-align: center;
    padding: 0.2rem;
    background-color: lighten($color: $secondary-color-light, $amount: 60%);
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin: 0 0.3rem;
    }
  }

  .logo-link-div {
    width: 20rem;
  }

  .custom-select {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-end;
    margin-top: -4rem;
  }

  .media-select {
    display: flex;
    flex-flow: column;
    width: 80%;
    margin: 0 auto 1rem;
  }
}

.statistics-header {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
}

.selections-btn {
  opacity: 0;
  margin: 0 auto 0.7rem;
  color: $primary-color;
  font-size: $small;
  font-weight: bold;
  transition: opacity 0.5s;
}

.selections-btn-show {
  opacity: 1;
}

.selections {
  display: none;
}

.selections-show {
  display: initial;
}

.statistics-iframe {
  flex: 1;
}

@include media-l {
  .statistics {
    .custom-select {
      margin-top: 0;
    }
  }
}

@include media-s {
  .statistics {
    .logo-link-div {
      width: auto;
    }
  }
}
