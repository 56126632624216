@import "../../styles/styles.scss";

.mainTitle {
  padding: 8rem 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row wrap;

  .logo {
    text-align: right;

    img {
      width: 450px;
      padding: 3rem;
    }
  }

  .heading {
    min-width: 920px;

    h1 {
      font-size: 5.5rem;
    }

    h2 {
      padding: 0.6rem;
      max-width: 900px;
      font-size: 2rem;

      span {
        color: $primary-color;
      }
    }
  }
}

@include media-l {
  .mainTitle {
    .logo {
      img {
        max-width: 500px;
      }
    }

    .heading {
      min-width: 500px;

      h1 {
        font-size: 6.5rem;
      }

      h2 {
        max-width: 700px;
      }
    }
  }
}

@include media-m {
  .mainTitle {
    text-align: center;
    padding: 3rem 0 0;

    .logo {
      text-align: center;
      width: 100%;

      img {
        max-width: 450px;
      }
    }

    .heading {
      width: 100%;
      min-width: 300px;

      h1 {
        font-size: 5rem;
      }

      h2 {
        font-size: $large;
        max-width: 600px;
        margin: auto;
      }
    }
  }
}

@include media-s {
  .mainTitle {
    .logo {
      img {
        max-width: 300px;
      }
    }

    .heading {
      min-width: 200px;
      h1 {
        font-size: $large;
      }

      h2 {
        font-size: $regular;
        max-width: 400px;
      }
    }
  }
}
