@import "../../../styles/styles.scss";

.card {
  @include card;
  padding: 2rem 4rem;
  margin: 5% auto 0;
  text-align: center;
}

@include media-s {
  .card {
    min-width: 320px;
    width: 90%;
    padding: 1rem 2rem;
  }
}
