// FONT
@mixin font($font-family, $font-file) {
  @font-face {
    font-family: $font-family;
    src: url($font-file + ".ttf");
    font-weight: normal;
    font-style: normal;
  }
}

// TRANSITION
@mixin transition-ease {
  transition: all 0.5s ease-in-out;
}

// MEDIA
@mixin media-s {
  @media all and (max-width: 600px) {
    @content;
  }
}

@mixin media-m {
  @media all and (max-width: 1120px) {
    @content;
  }
}

@mixin media-l {
  @media all and (max-width: 1540px) {
    @content;
  }
}

// CARD
@mixin card {
  background: $background-color;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 35rem;
}

// BUTTON
@mixin button {
  border: none;
  border-radius: 2px;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;

  height: 3.5rem;
  color: $background-color;
  font-family: $heading-font;
  font-size: $regular;
  font-weight: bold;
  padding: 0.3rem 3rem;

  background-color: $primary-color;
  @include transition-ease;

  &:hover {
    cursor: pointer;
    background-color: $accent-color;
  }
}
