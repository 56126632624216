@import "../../styles/styles.scss";

.subscriptions {
  h2 {
    margin-bottom: 3rem;
  }

  .no-bold {
    font-weight: normal;
  }
  
  a {
    text-decoration: none;
    h3 {
      margin-bottom: 0.3rem;
      span {
        font-weight: bold;
      }
    }
  }

  .m-1 {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }

  .subscriptions-list {
    width: 25rem;
    margin: auto;
  }

  .bundle-subscription {
    margin: 0.5rem 0 0 1.4rem;
  }

  input[type="checkbox"] {
    accent-color: $blue;
    height: 1rem;
    width: 1rem;
    cursor: pointer;
  }

  input[type="radio"] {
    accent-color: $blue;
    height: 1rem;
    width: 1rem;
    cursor: pointer;
  }
}

@include media-s {
  .subscriptions {
    padding: 0 1rem;
  }
}
