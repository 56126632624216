@import "../../../styles/styles.scss";

.logo-link {
  text-decoration: none;

  .logo-img {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.2rem;

    img {
      max-width: 50px;
    }

    h1 {
      font-size: $regular;
      display: inline;
      margin: 1rem;
    }
  }
}
