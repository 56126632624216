@import "./mixins";
@include font("Montserrat", "../assets/fonts/Montserrat-Regular");
@include font("Quicksand", "../assets/fonts/Quicksand-Regular");

//Fonts
$heading-font: "Montserrat", "Open Sans", "Helvetica Neue", sans-serif;
$body-font: "Quicksand", "Open Sans", "Helvetica Neue", sans-serif;

//Font size
$extra-small: 0.9rem;
$small: 1.2rem;
$medium: 1.3rem;
$regular: 1.7rem;
$large: 2.2rem;

// Colors
$primary-color: #d84c00;
$secondary-color: #272727;
$secondary-color-light: #555555;
$accent-color: #e3710f;
$background-color: #fff;
$error-color: #d61c1f;
$blue: #3c4f79;
$green: #198754;
