@import "../../styles/styles.scss";
.signup-form {
  .recaptcha-div {
    margin: 1.5rem auto 0;
  }

  .link-div {
    @include card;
    margin: 1rem auto;
    padding: 1.2rem;
    text-align: center;
    color: $secondary-color;
    font-size: $small;
    font-family: $body-font;

    a {
      border: none;
      background-color: transparent;
      font-size: $small;
      font-weight: bold;
      font-family: $body-font;
      margin-left: 0.5rem;
      color: $primary-color;
      cursor: pointer;
      text-decoration: none;
      @include transition-ease;

      &:hover {
        color: $accent-color;
      }
    }
  }
}

@include media-s {
  .signup-form {
    min-width: 320px;

    .link-div {
      width: 90%;
    }
  }
}
