@import "../../../styles/styles.scss";

.home-header {
  .home-link-div {
    width: 20rem;
    position: relative;
    z-index: 10;
  }

  .statistics-button {
    margin-top: 1.6rem;
    margin-right: 1rem;
    margin-left: 1rem;
    height: 2.5rem;

    color: $primary-color;
    font-family: $heading-font;
    font-size: $small;
    font-weight: bold;
    text-decoration: none;
  }

  .header-actions {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
  }
}

@include media-m {
  .home-header {
    .home-link-div {
      margin: auto;
    }

    .header-actions {
      position: static;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
}

@include media-s {
  .home-header {
    .header-actions {
      margin-bottom: 2rem;
    }
    .header-actions-close {
      margin-bottom: 0;
    }
  }
}
