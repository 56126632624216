@import "../../../styles/styles.scss";

.subscriptions-table {
  margin-top: 3rem;
  overflow: auto;

  .table-row {
    display: flex;
    margin: auto;
    width: 60rem;
    margin: 0.5rem auto;
    border: 1px solid $blue;
    border-radius: 4px;

    &.row-selected {
      box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
    }

    &.first {
      border: none;
      box-shadow: none;
    }

    .table-data {
      margin: 1rem 0;
      width: 20rem;

      .bundle {
        margin: 0.5rem;
      }

      .offset-left {
        margin-left: 3.6rem;
      }
    }

    .table-data-small {
      @extend .table-data;
      width: 10rem;
    }

    .checkbox-data {
      margin: 1.3rem;
    }

    &.total {
      margin-top: 0.6rem;
      justify-content: end;
      border: none;
      box-shadow: none;
    }

    .expiration {
      color: $primary-color;
    }
  }
}

.table-data-item {
  margin-left: 1rem;
}

@include media-s {
  .subscriptions-table {
    font-size: 1rem;
    .table-row {
      width: 100%;

      .table-data {
        width: 27%;
        h5 {
          font-size: 0.8rem;
        }
      }

      .table-data-small {
        width: 20%;
      }
    }
  }
}
