@import "../../styles/styles.scss";

nav {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;

  background: $background-color;
  opacity: 0.9;

  .navigation {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    align-items: baseline;

    list-style: none;
    margin-right: 8rem;

    li {
      a {
        color: $primary-color;
        display: block;
        font-family: $heading-font;
        font-size: $regular;
        font-weight: bold;
        padding: 1.5rem;
        text-decoration: none;
        @include transition-ease;

        &:hover {
          color: $accent-color;
        }
      }

      .login-button {
        @include button;
        margin-left: 2rem;
      }
    }
  }
}

.menu-btn {
  display: none;
  cursor: pointer;
  height: 20px;
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 28px;
  z-index: 1;
  @include transition-ease;

  &__burger {
    background: $secondary-color;
    height: 3px;
    position: absolute;
    right: 0;
    top: 0.5rem;
    width: 28px;
    @include transition-ease;

    &::before {
      background: $secondary-color;
      content: "";
      height: 3px;
      position: absolute;
      top: -8px;
      width: 28px;
      @include transition-ease;
    }

    &::after {
      background: $secondary-color;
      content: "";
      height: 3px;
      position: absolute;
      top: 8px;
      width: 28px;
      @include transition-ease;
    }

    &.open {
      background: transparent;
      transform: rotate(360deg);

      &::before {
        transform: rotate(45deg) translate(5px, 8px);
      }

      &::after {
        transform: rotate(-45deg) translate(3px, -7px);
        width: 28px;
      }
    }
  }
}

@include media-m {
  .menu-btn {
    display: inline;
  }

  nav {
    position: static;
    z-index: 0;
    padding-bottom: 2rem;

    .navigation {
      flex-flow: column wrap;
      justify-content: center;
      align-items: stretch;

      margin-right: 0;

      li {
        margin: 0 auto;
        a {
          text-align: center;
        }
        .login-button {
          margin-left: 0;
          margin-top: 1rem;
        }
      }
    }
    &.closed {
      display: none;
    }
    &.open {
      display: block;
      background-color: #eeeeee;
    }
  }
}
