@import "../../styles/styles.scss";

footer {
  padding: 5rem 0 0 0;

  .social-icons {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    a {
      margin: 2rem 0.5rem;
      color: $secondary-color;
      text-decoration: none;

      img {
        @include transition-ease;
        height: 3rem;
      }

      &:hover {
        h3 {
          color: $primary-color;
        }

        img {
          background-color: $primary-color;
          border-radius: 50%;
        }
      }
    }
  }
}

.creator {
  margin: 0 0.2rem 0.2rem auto;
  width: fit-content;

  p {
    margin: 0;
    color: $background-color;
  }
}

.email {
  font-size: 1.3rem;
  text-decoration: none;
  color: $primary-color;

  &:hover {
    color: $secondary-color;
  }
}

@include media-s {
  footer {
    .footer-text {
      font-size: $small;
      margin: 1rem;
    }

    .social-icons {
      a {
        img {
          height: 2rem;
        }
      }
    }

    .creator {
      margin: auto;
    }
  }
}
