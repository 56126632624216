@import "../../styles/styles.scss";

.checkout-table {
  max-width: 50rem;
  margin: 2rem auto;

  .checkout-table-header {
    border-bottom: 2px solid $blue;
    padding: 1rem 1.5rem;
  }

  .checkout-table-data {
    border-bottom: 1px solid $blue;
    padding: 1.5rem;
    display: flex;
    justify-content: space-between;

    .item-label {
      margin-right: auto;
    }

    .item-expiration {
      margin-left: 10rem;
    }
  }

  .checkout-table-footer {
    border-top: 1px solid $blue;
    padding: 2rem 1.5rem;

    display: flex;
    justify-content: space-between;
  }

  .checkout-total {
    text-align: center;
    border-top: 2px solid $blue;

    h4 {
      margin: 2rem;
    }
  }
}

.checkout-paypal-wrapper {
  display: flex;
  justify-content: center;
}

.checkout-paypal {
  width: 40rem;
}

@include media-s {
  .checkout {
    padding: 0 1rem;
  }
  .checkout-table {
    .checkout-table-data {
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 1rem;

      .item-label {
        margin-right: initial;
      }

      .item-expiration {
        margin-left: initial;
      }
    }
  }
}
