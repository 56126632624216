@import "../../../styles/styles.scss";

.logout {
  color: $primary-color;
  display: block;
  font-family: $heading-font;
  font-size: $small;
  font-weight: bold;
  padding: 1.5rem;
  text-decoration: none;
  @include transition-ease;

  &:hover {
    color: $accent-color;
  }
}
