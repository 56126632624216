@import "../../styles/styles.scss";

.signature-container {
    text-align: right;
}

.signature {
    margin-right: 1rem;
    padding-bottom: 0.2rem;
    text-decoration: none;
    color: $primary-color;

    &:hover {
        color: $secondary-color;
    }
}